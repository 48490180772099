import React, { PropsWithChildren } from "react"
import { Route, Redirect } from "react-router-dom"

import routes from "./routes"

type Props = PropsWithChildren<{
  path: string
  isAuthenticated: boolean
}>

const PrivateRoute: React.FC<Props> = ({
  path,
  isAuthenticated,
  children
}: Props) => {
  return (
    <Route path={path}>
      {isAuthenticated ? children : <Redirect exact to={routes.signIn} />}
    </Route>
  )
}

export default PrivateRoute
