import React from "react"

import Loading from "../Loading/Loading"
import styles from "./Button.module.scss"

type Props = {
  text: string
  onClick: () => void
  loading?: boolean
}

const Button: React.FC<Props> = ({ text, onClick, loading = false }: Props) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <div className={styles.buttonInnerContainer}>
        <div className={`${loading ? styles.buttonText : ""}`}>
          {loading ? "Loading" : text}
        </div>
        {loading && <Loading variant="secondary" />}
      </div>
    </button>
  )
}

export default Button
