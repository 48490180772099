import { useMediaQuery } from "react-responsive"

type IsDeviceType = "isMobile" | "isTablet" | "isLaptop" | "isDesktop"

export const useBreakpoints: () => { [key in IsDeviceType]: boolean } = () => {
  const isMobile = useMediaQuery({
    query: "(min-device-width: 480px)"
  })

  const isTablet = useMediaQuery({
    query: "(min-device-width: 768px)"
  })

  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)"
  })

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1200px)"
  })

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop
  }
}
