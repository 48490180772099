import { EndpointKey } from "./constants"

export enum ErrorType {
  BAD_REQUEST = "BAD_REQUEST",
  FORBIDDEN = "FORBIDDEN",
  UNAUTHORIZED = "UNAUTHORIZED",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  EXPIRED_ORDER = "EXPIRED_ORDER",
  PROCESSED_ORDER = "PROCESSED_ORDER",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS"
}

const useGeneralErrorMessages: () => { [key in ErrorType]?: string } = () => {
  return {
    [ErrorType.BAD_REQUEST]: "The provided URL format is wrong.",
    [ErrorType.FORBIDDEN]: "FORBIDDEN",
    [ErrorType.UNAUTHORIZED]: "UNAUTHORIZED"
  }
}

export const useErrorMessages: () => {
  [key in EndpointKey]: {
    [key in ErrorType]?: string
  }
} = () => {
  const generalErrorMessages = useGeneralErrorMessages()
  return {
    getOrderSummary: {
      ...generalErrorMessages,
      [ErrorType.ORDER_NOT_FOUND]:
        "The payment for the provided URL could not be found."
    },
    confirmOrder: {
      ...generalErrorMessages,
      [ErrorType.EXPIRED_ORDER]: "This payment has expired.",
      [ErrorType.PROCESSED_ORDER]: "This payment has already been paid.",
      [ErrorType.INSUFFICIENT_FUNDS]:
        "You don't have enough funds in your account to complete the payment."
    }
  }
}
