/* eslint-disable no-console */
import React, { PropsWithChildren, useEffect, useState } from "react"
import { Hub, HubCapsule } from "@aws-amplify/core"

import { getCurrentUserAsync, User } from "../Auth"

type UserContextType = {
  user: User
  isLoading: boolean
}

export const UserContext = React.createContext<UserContextType>({
  user: null,
  isLoading: true
})

type Props = PropsWithChildren<{}>

export const UserContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [user, setUser] = useState<User>(null)
  const [isLoading, setIsLoading] = useState(true)

  const listener = async (data: HubCapsule) => {
    switch (data.payload.event) {
      case "signIn":
        console.log("User signed in")
        setUser(await getCurrentUserAsync())
        break
      case "signUp":
        console.log("User signed up")
        setUser(await getCurrentUserAsync())
        break
      case "signOut":
        console.log("User signed out")
        setUser(null)
        break
      case "signIn_failure":
        console.log("User sign in failed")
        break
      case "tokenRefresh":
        console.log("Token refresh succeeded")
        break
      case "tokenRefresh_failure":
        console.log("Token refresh failed")
        setUser(null)
        break
      case "configured":
        console.log("The Auth module is configured")
    }
    console.log(data.payload)
  }

  useEffect(() => {
    const bootstrapUser = async () => {
      setUser(await getCurrentUserAsync())
      setIsLoading(false)
    }

    Hub.listen("auth", listener)
    bootstrapUser()
    return () => {
      Hub.remove("auth", listener)
    }
  }, [])

  return (
    <UserContext.Provider value={{ user, isLoading }}>
      {children}
    </UserContext.Provider>
  )
}
