/* eslint-disable no-console */
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult
} from "react-query"

import { EndpointKey } from "./constants"
import { ErrorType, useErrorMessages } from "./errorMessages"
import { getIdTokenAsync } from "./Auth"

export const useHeaders: (headers?: {
  [key: string]: string
}) => () => Promise<{ [key: string]: string }> =
  (headers = {}) =>
  async () => ({
    Authorization: `Bearer ${await getIdTokenAsync()}`,
    ...headers
  })

export function useGet<T>(
  key: QueryKey,
  endpoint: string,
  options: UseQueryOptions<T, Error, T> = {}
): UseQueryResult<T, Error> {
  const getHeaders = useHeaders()
  const errorMessages = useErrorMessages()

  return useQuery<T, Error>(
    key,
    async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_PUBLIC_API_URL + endpoint,
          {
            method: "GET",
            headers: await getHeaders()
          }
        )

        if (!response.ok) {
          const payload = await response.json()
          throw new Error(
            errorMessages[key as EndpointKey][payload.message as ErrorType]
          )
        }

        return (await response.json()) as T
      } catch (e) {
        console.error(
          "Error useGet crashed",
          e,
          process.env.REACT_APP_PUBLIC_API_URL + endpoint
        )

        throw new Error(
          e?.message || "Something went wrong, network request has failed."
        )
      }
    },
    {
      cacheTime: 0,
      retry: false,
      ...options
    }
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseType = string | { [key: string]: any }

export function useSet<T>(
  key: QueryKey,
  endpoint: string,
  method: "POST" | "PUT" | "PATCH" | "DELETE",
  options: UseMutationOptions<ResponseType, Error, T> = {},
  invalidationKeys?: string | string[]
): UseMutationResult<ResponseType, Error, T> {
  const getHeaders = useHeaders({ "Content-Type": "application/json" })
  const client = useQueryClient()
  const errorMessages = useErrorMessages()

  return useMutation(
    key,
    async (data: T) => {
      try {
        const response = await fetch(
          process.env.REACT_APP_PUBLIC_API_URL + endpoint,
          {
            method,
            headers: await getHeaders(),
            body: data ? JSON.stringify(data) : null
          }
        )

        if (!response.ok) {
          const payload = await response.json()
          throw new Error(
            errorMessages[key as EndpointKey][payload.message as ErrorType]
          )
        }

        const text = await response.text()

        return text.length && ["{", "["].includes(text[0])
          ? JSON.parse(text)
          : text
      } catch (e) {
        console.error(
          "Error useSet crashed",
          e,
          process.env.REACT_APP_PUBLIC_API_URL + endpoint,
          data
        )

        throw new Error(
          e?.message || "Something went wrong, network request has failed."
        )
      }
    },
    {
      onSuccess: () => {
        if (invalidationKeys && Array.isArray(invalidationKeys)) {
          invalidationKeys.forEach((_key) => client.invalidateQueries(_key))
        } else {
          client.invalidateQueries(key)
        }
      },
      ...options
    }
  )
}
