import React from "react"

import Lock from "./Lock"
import Success from "./Success"
import User from "./User"

export type IconName = "Lock" | "User" | "Success"

const iconsComponentMap: { [key in IconName]: React.ReactElement } = {
  Lock: <Lock />,
  Success: <Success />,
  User: <User />
}

type Props = {
  name: IconName
}

const Icon: React.FC<Props> = ({ name }: Props) => {
  return iconsComponentMap[name]
}

export default Icon
