import React, { PropsWithChildren } from "react"
import { Route, Redirect } from "react-router-dom"

import routes from "./routes"

type Props = PropsWithChildren<{
  path: string
}>

const PublicRoute: React.FC<Props> = ({ path, children }: Props) => {
  return (
    <Route path={path}>
      {/* {path ? children : <Redirect exact to={routes.signIn} />} */}
      {children}
    </Route>
  )
}

export default PublicRoute
