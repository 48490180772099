import React from "react"
import { UseFormRegisterReturn, FieldError } from "react-hook-form"

import Icon, { IconName } from "../../icons"
import styles from "./Input.module.scss"

type Props = {
  label: string
  register: UseFormRegisterReturn
  type: HTMLInputElement["type"]
  iconName: IconName
  placeholder?: string
  error?: FieldError
}

const Input: React.FC<Props> = ({
  label,
  register,
  type,
  iconName,
  placeholder,
  error
}: Props) => {
  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <div
        className={`${styles.inputContainer} ${error ? styles.inputError : ""}`}
      >
        <Icon name={iconName} />
        <input
          className={styles.input}
          type={type}
          placeholder={placeholder}
          {...register}
        />
      </div>
      <p className={styles.error}>{error?.message}</p>
    </div>
  )
}

export default Input
