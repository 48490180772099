/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useGet, useSet } from "../../api"
import { Asset, EndpointKey, FiatAsset, useApi } from "../../constants"

type OrderSummaryData = {
  uuid: string
  amount: number
  currency: Asset
  fiatAmount: number
  fiatCurrency: FiatAsset
  target?: { name: string }
  createdAt: Date
  externalOrderId: string
  description: string
}

type ConfirmOrderData = {
  order_id: string
}

export const useGetOrderSummary = (orderId: string) =>
  useGet<OrderSummaryData>(
    EndpointKey.GET_ORDER_SUMMARY,
    useApi().getOrderSummary(orderId),
    {}
  )

export const useConfirmOrder = () =>
  useSet<ConfirmOrderData>(
    EndpointKey.CONFIRM_ORDER,
    useApi().confirmOrder(),
    "POST",
    {},
    [EndpointKey.CONFIRM_ORDER, EndpointKey.GET_ORDER_SUMMARY]
  )
