import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

import Icon from "../../icons"
import Layout from "../../ui/Layout/Layout"
import styles from "./Success.module.scss"

const REDIRECT_TIMEOUT = 5 // in seconds

const Success: React.FC = () => {
  const { search } = useLocation()
  const redirect = new URLSearchParams(search).get("redirect") || ""

  useEffect(() => {
    if (redirect) {
      const timer = setTimeout(function () {
        window.location.replace(redirect)
      }, REDIRECT_TIMEOUT * 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [search])

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.success}>
          Your payment has been completed successfully!
        </div>
        <div className={styles.success}>
          In a few seconds you will be redirected...
        </div>
        <Icon name="Success" />
      </div>
    </Layout>
  )
}

export default Success
