import React, { useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

import routes from "../../routes"
import styles from "./Summary.module.scss"
import Layout from "../../ui/Layout/Layout"
import { useConfirmOrder, useGetOrderSummary } from "../Success/api"
import ErrorMessage from "../../ui/ErrorMessage/ErrorMessage"
import Loading from "../../ui/Loading/Loading"
import { UserContext } from "../../context/User"
import { FiatSign, AssetNames } from "../../constants"

const Summary: React.FC = () => {
  const history = useHistory()
  const { user } = useContext(UserContext)
  const { search } = useLocation()
  const order_id = new URLSearchParams(search).get("order_id") || ""
  const {
    data: order,
    isLoading: isLoadingOrder,
    isSuccess: isSuccessOrder,
    error: errorOrder
  } = useGetOrderSummary(order_id)
  const {
    mutate: confirmOrder,
    isLoading: isLoadingConfirmation,
    isSuccess: isSuccessConfirmation,
    error: errorConfirmation
  } = useConfirmOrder()

  useEffect(() => {
    if (isSuccessConfirmation) {
      history.push({ pathname: routes.success, search })
    }
  }, [isSuccessConfirmation])

  const onSubmit = () => {
    confirmOrder({ order_id })
  }

  return (
    <Layout
      title="Payment summary"
      buttonText="Confirm payment"
      onClick={isSuccessOrder ? onSubmit : undefined}
      loading={isLoadingConfirmation}
    >
      {isLoadingOrder ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : errorOrder ? (
        <ErrorMessage error={errorOrder} />
      ) : (
        order && (
          <>
            <div className={styles.row}>
              <div className={styles.itemTitle}>From</div>
              <div>
                <div className={styles.itemValue}>
                  {user?.attributes?.email}
                </div>
                <div className={styles.itemValue}>
                  {AssetNames[order.currency]} Wallet
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.itemTitle}>To</div>
              <div>
                <div className={styles.itemValue}>{order.target?.name}</div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.itemTitle}>Description</div>
              <div>
                <div className={styles.itemValue}>{order.description}</div>
              </div>
            </div>

            <div className={styles.divider} />

            <div className={styles.row}>
              <div className={styles.itemTitle}>Amount</div>
              <div>
                <div className={styles.totalAmount}>
                  {order.amount} {order.currency}
                </div>
                {order.fiatCurrency && Number(order.fiatCurrency) > 0 && (
                  <div className={styles.totalAmount}>
                    =~ {FiatSign[order.fiatCurrency]}
                    {Number(order.fiatAmount)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      .replace(/0+$/, "0")}
                  </div>
                )}
              </div>
            </div>
          </>
        )
      )}

      <ErrorMessage error={errorConfirmation} />
    </Layout>
  )
}

export default Summary
