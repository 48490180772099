import React from "react"

const User: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0585 3.97585C13.5266 3.44325 12.7898 3.1119 11.9776 3.1119C11.1653 3.1119 10.4285 3.44325 9.89659 3.97585C9.36471 4.50956 9.0345 5.24897 9.0345 6.0651C9.0345 6.88012 9.36471 7.61843 9.89659 8.15325C10.4285 8.68696 11.1653 9.0172 11.9776 9.0172C12.7898 9.0172 13.5266 8.68696 14.0585 8.15325C14.5904 7.61954 14.9206 6.88012 14.9206 6.0651C14.9206 5.24897 14.5904 4.51067 14.0585 3.97585ZM15.5456 13.3792H8.40955C7.77573 13.3792 7.20064 13.6382 6.78511 14.0552L6.78289 14.0574C6.36626 14.4755 6.10808 15.0526 6.10808 15.6897V17.6033C6.10808 18.2371 6.36737 18.8153 6.78511 19.2333V19.2344C7.20064 19.6525 7.77684 19.9116 8.40955 19.9116H15.5445C16.1772 19.9116 16.7534 19.6514 17.17 19.2322C17.5866 18.8141 17.8459 18.236 17.8459 17.6022V15.6886C17.8459 15.0526 17.5889 14.4744 17.1722 14.0574H17.17C16.7534 13.6394 16.1783 13.3792 15.5456 13.3792ZM8.40955 12.2673H15.5445C16.483 12.2673 17.3351 12.652 17.9534 13.2713L17.9523 13.2724C18.5717 13.894 18.954 14.7479 18.954 15.6886V17.6022C18.954 18.5428 18.5706 19.399 17.9534 20.0183C17.3351 20.6388 16.483 21.0235 15.5445 21.0235H8.40955C7.47323 21.0235 6.62112 20.6377 6.00281 20.0172L6.0017 20.0183C5.38339 19.399 5 18.5428 5 17.6022V15.6886C5 14.749 5.38339 13.8951 6.0017 13.2735L6.00281 13.2724L6.00392 13.2713C6.62222 12.652 7.47433 12.2673 8.40955 12.2673ZM11.9776 2C13.0956 2 14.1095 2.45477 14.8419 3.18973C15.5744 3.92581 16.0287 4.94208 16.0287 6.0651C16.0287 7.18701 15.5744 8.20329 14.8419 8.93937C14.1084 9.67433 13.0956 10.1302 11.9776 10.1302C10.8595 10.1302 9.84672 9.67433 9.11318 8.93937C8.37963 8.20329 7.92643 7.18701 7.92643 6.0651C7.92643 4.94208 8.38074 3.92581 9.11318 3.18973C9.84672 2.45477 10.8595 2 11.9776 2Z"
        fill="#BFC3C9"
        stroke="#BFC3C9"
      />
    </svg>
  )
}

export default User
