import React from "react"

const Success: React.FC = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 0C17.9427 0 0 17.9427 0 40C0 62.0573 17.9427 80 40 80C62.0573 80 80 62.0542 80 40C80 17.9458 62.0573 0 40 0ZM40 73.8033C21.3633 73.8033 6.19672 58.6398 6.19672 40C6.19672 21.3602 21.3633 6.19672 40 6.19672C58.6398 6.19672 73.8033 21.3602 73.8033 40C73.8033 58.6398 58.6367 73.8033 40 73.8033Z"
        fill="#14AA37"
      />
      <path
        d="M58.3517 26.0883C57.0906 24.9419 55.1294 25.0317 53.9769 26.2989L35.0767 47.1108L25.9458 37.8281C24.7405 36.6073 22.7823 36.5887 21.5647 37.7909C20.3439 38.99 20.3253 40.9512 21.5275 42.172L32.9573 53.7909C33.543 54.3858 34.3361 54.7173 35.1664 54.7173C35.185 54.7173 35.2067 54.7173 35.2253 54.7205C36.0805 54.7019 36.8861 54.3362 37.4592 53.7042L58.5622 30.4664C59.7119 29.1959 59.6189 27.2378 58.3517 26.0883Z"
        fill="#14AA37"
      />
    </svg>
  )
}

export default Success
