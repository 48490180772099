import React from "react"

import styles from "./ErrorMessage.module.scss"

type Props = {
  error: Error | null
}

const ErrorMessage: React.FC<Props> = ({ error }: Props) => {
  return <div className={styles.error}>{error?.message}</div>
}

export default ErrorMessage
