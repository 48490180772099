import React from "react"

import styles from "./Loading.module.scss"

type Props = {
  variant?: "primary" | "secondary"
  size?: number
}

const Loading: React.FC<Props> = ({
  variant = "primary",
  size = 24
}: Props) => {
  return (
    <div
      className={`${styles.loading} ${
        variant === "primary" ? styles.primary : styles.secondary
      }`}
      style={{ width: size, height: size }}
    />
  )
}

export default Loading
