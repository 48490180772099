export type Asset = "BTC" | "ETH" | "USDT" | "WTK"
export const AssetNames: { [key in Asset]: string } = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  USDT: "Tether",
  WTK: "WTK"
}

export type FiatAsset = "USD" | "EUR" | "INR" | "IDR" | "WTK"
export const FiatSign: { [key in FiatAsset]: string } = {
  USD: "$",
  EUR: "€",
  INR: "₹",
  IDR: "Rp",
  WTK: "WTK"
}

export type Countries = "SINGAPORE" | "INDIA" | "USA"
export const BlockedCountries: { [key in Countries]: string } = {
  SINGAPORE: "Singapore",
  INDIA: "India",
  USA: "USA"
}

export enum EndpointKey {
  GET_ORDER_SUMMARY = "getOrderSummary",
  CONFIRM_ORDER = "confirmOrder"
}

export const useApi: () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in EndpointKey]: (...args: any[]) => string
} = () => {
  return {
    getOrderSummary: (orderId) => `user/order/${orderId}`,
    confirmOrder: () => `user/account/create_transaction_from_order/`
  } as const
}
