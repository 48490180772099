enum ROUTE {
  SIGN_IN = "signIn",
  SUMMARY = "summary",
  SUCCESS = "success",
  SUCCESSNOAUTH = "succesnoauth"
}

const routes: { [key in ROUTE]: string } = {
  signIn: "/",
  summary: "/summary",
  success: "/success",
  succesnoauth: "/successnoauth"
}

export default routes
