import React, { PropsWithChildren } from "react"

import logo from "../../../assets/amber_lounge_text.png"
import { useBreakpoints } from "../../../hooks"
import Button from "../Button/Button"
import styles from "./Layout.module.scss"

type Props = PropsWithChildren<{
  title?: string
  buttonText?: string
  onClick?: () => void
  loading?: boolean
}>

const Layout: React.FC<Props> = ({
  title,
  buttonText,
  onClick,
  loading = false,
  children
}: Props) => {
  const { isMobile } = useBreakpoints()

  const content = (
    <>
      <img src={logo} alt="Logo" width={300} className={styles.logo} />
      <div>
        {title && <div className={styles.title}>{title}</div>}
        {children}
      </div>
      {buttonText && onClick && (
        <div className={styles.buttonContainer}>
          <Button text={buttonText} onClick={onClick} loading={loading} />
        </div>
      )}
    </>
  )

  return (
    <div className={styles.container}>
      {isMobile ? <div className={styles.box}>{content}</div> : content}
    </div>
  )
}

export default Layout
