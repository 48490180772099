import { Auth } from "aws-amplify"

type CongnitoConfig = {
  aws_project_region: string
  aws_cognito_region: string
  aws_user_pools_id: string
  aws_user_pools_web_client_id: string
  oauth: { [key in string]: string }
}

const cognitoConfig: CongnitoConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION || "",
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION || "",
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID || "",
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || "",
  oauth: {}
}

Auth.configure(cognitoConfig)

export type IdToken = string | null
export type User =
  | {
      attributes: {
        email: string
        phone_number: string
        sub: string // same as username (cognito username - uuid)
      }
      username: string // same as sub (cognito username - uuid)
    }
  | null
  | undefined
export type SignInData = { email: string; password: string }

const signInAsync: (signInData: SignInData) => Promise<void> = async ({
  email,
  password
}) => {
  await Auth.signIn(email, password)
}

const signUpAsync: (signInData: SignInData) => void = async ({
  email,
  password
}) => {
  await Auth.signUp(email, password)
}

const signOutAsync: () => void = async () => {
  await Auth.signOut()
}

const requestResetPasswordCodeAsync: (body: {
  email: string
}) => Promise<void> = async ({ email }) => {
  await Auth.forgotPassword(email)
}

const submitResetPasswordCodeAsync: (body: {
  email: string
  code: string
  newPassword: string
}) => Promise<string> = ({ email, code, newPassword }) => {
  return Auth.forgotPasswordSubmit(email, code, newPassword)
}

const getCurrentUserAsync: () => Promise<User> = async () => {
  try {
    return await Auth.currentUserInfo()
  } catch {
    return null
  }
}

const getIdTokenAsync: () => Promise<IdToken> = async () => {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken()
  } catch {
    return null
  }
}

export {
  signInAsync,
  signUpAsync,
  signOutAsync,
  requestResetPasswordCodeAsync,
  submitResetPasswordCodeAsync,
  getCurrentUserAsync,
  getIdTokenAsync
}
